<template>
  <el-steps :active="steps" align-center>
    <el-step v-for="(item,index) in stepsList" :key="index" :title="item.nodeName" :status="disabled ? 'wait' : item.status">
      <template #description v-if="item.endNode">
        <el-tooltip class="item" effect="dark" :content="item.nodeInfo" placement="top">
          <div class="omit2">{{ item.nodeInfo }}</div>
        </el-tooltip>
        <div>{{ item.handleTime }}</div>
      </template>
    </el-step>
  </el-steps>
</template>

<script>
export default {
  name: "reviewSteps",
  props: {
    stepsList: {
      type: Array,
      default: () => []
    },
    //步骤
    steps: {
      type: Number,
      default: 0
    },
    //是否全部置灰
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
}
</script>

<style scoped>

</style>